import React from "react"
import Layout from "../components/layout"
import { StaticImage } from "gatsby-plugin-image"
import { Helmet } from "react-helmet"

const About = ({ data }) => {

    console.log(data);

    return (
        <Layout>
            <Helmet>
                <title>Brian Stewart - About - Solution architect, software engineer, and product technology professional living in Asheville, North Carolina</title>
            </Helmet>
            <div className="about-container">
                <h1><strong>ABOUT</strong></h1>
                <div>
                    <StaticImage
                        src="../images/brianstewart.jpg"
                        alt="Brian Stewart"
                        placeholder="blurred"
                        maxWidth={500}
                    />
                    <h1>Hi there</h1>
                    <p>I'm a solution architect, software engineer, and product technology professional living in Asheville, North Carolina.  I'm passionate about engineering software systems in the cloud while endlessly pursuing Lean-Agile and DevOps betterment.  I jump around clouds, platforms, languages, and tools often.  I love creating things, and working with others to create even greater things.</p>
                    <p><em>Want to work together?  Awesome!  Get in touch via the methods listed below...</em></p>
                </div>

                <div>
                    <ul>
                        <li><a href="https://twitter.com/brianstewart_io" target="_blank" rel="noreferrer">Twitter</a></li>
                        <li><a href="https://github.com/thisisbrianstewart" target="_blank" rel="noreferrer">Github</a></li>
                        <li><a href="https://www.linkedin.com/in/thisisbrianstewart" target="_blank" rel="noreferrer">LinkedIn</a></li>
                        <li><a href="mailto:thisisbrianstewart@gmail.com" target="_blank" rel="noreferrer">Email</a></li>
                    </ul>
                </div>
            </div>

        </Layout>
    )    
}

export default About;